<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: `/exam-result/${record.id}/edit` }"
      v-if="record && hasPermissionToEdit"
    >
      <el-button icon="el-icon-fa-edit" type="primary" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="common.edit"></app-i18n> </span>
      </el-button>
    </router-link>

    <el-button
      :disabled="destroyLoading"
      @click="doDestroyWithConfirm"
      icon="el-icon-fa-trash"
      type="primary"
      v-if="record && hasPermissionToDestroy"
      :class="rtlAndPadding"
    >
     <span class="card-title"> <app-i18n code="common.destroy"></app-i18n> </span>
    </el-button>

    <router-link
      :to="{
        path: '/audit-logs',
        query: { entityId: record.id },
      }"
      v-if="record && hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="auditLog.menu"></app-i18n> </span>
      </el-button>
    </router-link>

    <el-button
            :disabled="reScoreLoading"
            @click="doReScore"
            icon="el-icon-fa-retweet"
            type="primary"
            v-if="record && hasPermissionToEdit"
            :class="rtlAndPadding"
    >
      <span class="card-title"> <app-i18n code="entities.examResult.reScore.button"></app-i18n> </span>
    </el-button>
  </div>
</template>

<script>
import { ExamResultPermissions } from '@/modules/exam-result/exam-result-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-exam-result-view-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'examResult/view/record',
      loading: 'examResult/view/loading',
      reScoreLoading: 'examResult/view/reScoreLoading',
      destroyLoading: 'examResult/destroy/loading',
      rtlAndPadding: 'examResult/form/rtlAndPadding'
    }),

    hasPermissionToEdit() {
      return new ExamResultPermissions(this.currentUser)
        .edit;
    },

    hasPermissionToImport() {
      return new ExamResultPermissions(this.currentUser)
        .import;
    },

    hasPermissionToDestroy() {
      return new ExamResultPermissions(this.currentUser)
        .destroy;
    },

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },
  },

  methods: {
    ...mapActions({
      doDestroy: 'examResult/destroy/doDestroy',
      _doReScore: 'examResult/view/doReScore',
    }),

    async doReScore(){
      try {
        await this.$confirm(
                i18n('common.areYouSure'),
                i18n('common.confirm'),
                {
                  confirmButtonText: i18n('common.yes'),
                  cancelButtonText: i18n('common.no'),
                  type: 'warning',
                },
        );
        this._doReScore({
          examId: this.record.examId,
          studentId: this.record.studentId,
          examResultId: this.record.id
        });
      }catch (error) {
        console.log("No Selected: " + error.message)
      }
    },

    async doDestroyWithConfirm() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(this.record.id);
      } catch (error) {
        console.log("No Selected: " + error.message)
      }
    },
  },
};
</script>

<style></style>
