<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/exam-result' }">
        <app-i18n code="entities.examResult.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.examResult.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.examResult.view.title"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>

      <app-exam-result-view-toolbar v-if="record"></app-exam-result-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text :label="fields.id.label" :value="presenter(record, 'id')"></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.student.label"
          :permission="fields.student.readPermission"
          :url="fields.student.viewUrl"
          :value="presenter(record, 'student')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.exam.label"
          :permission="fields.exam.readPermission"
          :url="fields.exam.viewUrl"
          :value="presenter(record, 'exam')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
                :label="fields.examSessionDate.label"
                :value="presenterAsDay('examSessionDate')"
        ></app-view-item-text>



        <div class="sideBySide">
          <div class="cskill">



            <app-view-item-text
                    :label="fields.aftermath.label"
                    :value="presenter(record, 'aftermath')"
            ></app-view-item-text>

        <app-view-item-text
          :label="fields.speaking.label"
          :value="presenter(record, 'speaking')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.writing.label"
          :value="presenter(record, 'writing')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.reading.label"
          :value="presenter(record, 'reading')"
        ></app-view-item-text>

        <app-view-item-text
           :label="fields.listening.label"
           :value="presenter(record, 'listening')"
        ></app-view-item-text>
        </div>
        <div class="eSkill">
        <app-view-item-text
            :label="fields.grammar.label"
            :value="presenter(record, 'grammar')"
        ></app-view-item-text>

            <app-view-item-text
                    :label="fields.oralFluency.label"
                    :value="presenter(record, 'oralFluency')"
            ></app-view-item-text>

            <app-view-item-text
                    :label="fields.pronunciation.label"
                    :value="presenter(record, 'pronunciation')"
            ></app-view-item-text>

            <app-view-item-text
                    :label="fields.spelling.label"
                    :value="presenter(record, 'spelling')"
            ></app-view-item-text>

            <app-view-item-text
                    :label="fields.vocabulary.label"
                    :value="presenter(record, 'vocabulary')"
            ></app-view-item-text>

            <app-view-item-text
                    :label="fields.writtenDiscourse.label"
                    :value="presenter(record, 'writtenDiscourse')"
            ></app-view-item-text>
          </div>
        </div>

        <app-view-item-text
            v-if="!isStudent"
            :label="fields.resultStatus.label"
            :value="presenter(record, 'resultStatus')"
        ></app-view-item-text>

        <app-view-item-text
                :label="fields.answeredQC.label"
                :value="`${presenter(record, 'answeredQC')} / ${presenter(record, 'totalQC')}`"
        ></app-view-item-text>

        <app-view-item-text
                :label="fields.totalTimeSpent.label"
                :value="record.totalTimeSpent | formatDurationFilter"
        ></app-view-item-text>


        <app-view-item-text v-if="!isStudent" :label="fields.createdAt.label" :value="presenter(record, 'createdAt')"></app-view-item-text>

        <app-view-item-text v-if="!isStudent" :label="fields.updatedAt.label" :value="presenter(record, 'updatedAt')"></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ExamResultViewToolbar from '@/modules/exam-result/components/exam-result-view-toolbar.vue';
import { ExamResultModel } from '@/modules/exam-result/exam-result-model';
import moment from 'moment';

const { fields } = ExamResultModel;

export default {
  name: 'app-exam-result-view-page',

  props: ['id'],

  components: {
    [ExamResultViewToolbar.name]: ExamResultViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'examResult/view/record',
      loading: 'examResult/view/loading',
      isStudent: 'auth/isStudent',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'examResult/view/doFind',
    }),

    presenter(record, fieldName) {
      return ExamResultModel.presenter(record, fieldName);
    },

    presenterAsDay(fieldName){
      return moment(this.record[this.fields[fieldName].name]).format('YYYY-MM-DD')
    }
  },
};
</script>

<style scoped>
  .sideBySide {
    display: flex;
  }
</style>
